import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImageBlogRoll from "./PreviewCompatibleImageBlogRoll";

var paragraphs = require('lines-to-paragraphs');

class BlogRoll extends React.Component {
    render() {
        const { data } = this.props
        const { edges: posts } = data.allMarkdownRemark

        return (
            <div className="columns is-multiline">
                {posts &&
                    posts.map(({ node: post }) => (
                        <div className="is-parent column is-6" key={post.id}>
                            <article style={{ backgroundColor: "whitesmoke"}}
                                className={`tile is-child box ${post.frontmatter.featuredpost ? 'is-featured' : ''
                                }`}
                            >
                                    <p className="post-meta is-underlined">
                                        <Link
                                            className="title has-text-primary is-size-4"
                                            to={post.fields.slug}
                                        >
                                            {post.frontmatter.title}
                                        </Link>
                                    </p>
                                <div className="columns" style={{ marginBottom: "0"}}>
                                    <div className="column is-12 is-centered">
                                        {post.frontmatter.featuredimage ? (
                                            <div className="featured-thumbnail has-text-centered" >
                                                <PreviewCompatibleImageBlogRoll
                                                    imageInfo={{
                                                        image: post.frontmatter.featuredimage,
                                                        alt: `${post.frontmatter.altfeaturedimage}`,
                                                    }}
                                                />
                                            </div>
                                        ) : null}
                                    </div>

                                </div>
                                <div style={{ marginBottom: "1rem"}}>
                                    <p>
                                        <div dangerouslySetInnerHTML={{ __html: paragraphs(post.frontmatter.description) }}/>
                                    </p>
                                </div>
                                <div className="has-text-centered">
                                    <Link className="button" to={post.fields.slug}>
                                        Voir les photos →
                                    </Link>
                                </div>
                            </article>
                        </div>
                    ))}
            </div>
        )
    }
}

BlogRoll.propTypes = {
    data: PropTypes.shape({
        allMarkdownRemark: PropTypes.shape({
            edges: PropTypes.array,
        }),
    }),
}

export default () => (
    <StaticQuery
        query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                description
                date(formatString: "MMMM DD, YYYY")
                featuredpost
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 600, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                altfeaturedimage
              }
            }
          }
        }
      }
    `}
        render={(data, count) => <BlogRoll data={data} count={count} />}
    />
)
