import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

const PreviewCompatibleImageBlogRoll = ({ imageInfo }) => {
  const { alt = '', childImageSharp, image } = imageInfo
  const imageStyle = { borderRadius: '0px',  width: "100%", margin: "auto", maxHeight: "280px" }


  if (!!image && !!image.childImageSharp) {
    return (
      <Img className="feature_image has-text-centered" style={imageStyle} fluid={image.childImageSharp.fluid} alt={alt} />
    )
  }

  if (!!childImageSharp) {
    return <Img className="feature_image has-text-centered" style={imageStyle} fluid={childImageSharp.fluid} alt={alt} />
  }

  if (!!image && typeof image === 'string')
    return <img className="feature_image has-text-centered" style={imageStyle} src={image} alt={alt} />

  return null
}

PreviewCompatibleImageBlogRoll.propTypes = {
  imageInfo: PropTypes.shape({
    alt: PropTypes.string,
    childImageSharp: PropTypes.object,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    style: PropTypes.object,
  }).isRequired,
}

export default PreviewCompatibleImageBlogRoll
